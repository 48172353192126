import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import api from "services/api";
import { login } from "services/auth";
// import { Form, Container } from "layouts/styles/signin2"
// import Logo from "assets/img/reactlogo.png";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import avatar from "assets/img/faces/face-3.jpg";

class SignIn2 extends Component {
  
  state = {
    email: "",
    password: "",
    error: ""
  };

  handleSignIn = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("/authenticate", { email, password });
        login(response.data.token);
        this.props.history.push("/app");
      } catch (err) {
        this.setState({
          error:
            "Login ou senha inválido(s)"
        });
      }
    }
  };

  render() {
    return (
      <div className="wrapper wrapper-full-page">
      <div className="full-page login-page" data-color="black" data-image="/light-bootstrap-dashboard-pro-react/static/media/full-screen-image-3.ef9c8d65.jpg">                       
        <div className="content">
        <div className="container">
          <Row>
            <Col md={4} mdOffset={4} sm={6} smOffset={3} >
              
              <Card
                hCenter="true"
                title="Plataforma Long & Short"
                content={
                  <form>
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        
                        {
                          label: "E-mail",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "E-mail"
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Senha",
                          type: "password",
                          bsClass: "form-control",
                          
                        }
                        
                      ]}
                    />                    
                    <Button bsStyle="info" pullRight fill type="submit">
                      Acessar
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
      </div>
      </div>
      </div>
      </div>
    );
  }
}

export default withRouter(SignIn2);
