import Dashboard from "views/Dashboard.jsx";
import UserProfile from "views/UserProfile.jsx";
import TableList from "views/TableList.jsx";
import Typography from "views/Typography.jsx";
// import Icons from "views/Icons.jsx";
// import Maps from "views/Maps.jsx";
// import Notifications from "views/Notifications.jsx";
// import Upgrade from "views/Upgrade.jsx";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Análise",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/app"
  },
  {
    path: "/table",
    name: "Histórico",
    icon: "pe-7s-note2",
    component: TableList,
    layout: "/app"
  },
  {
    path: "/typography",
    name: "Operações",
    icon: "pe-7s-news-paper",
    component: Typography,
    layout: "/app"
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "pe-7s-science",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Mapas",
  //   icon: "pe-7s-map-marker",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "pe-7s-bell",
  //   component: Notifications,
  //   layout: "/admin"
  // },
  // {
  //   upgrade: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "pe-7s-rocket",
  //   component: Upgrade,
  //   layout: "/admin"
  // },
  
  {
    path: "/icons",
    name: "Ativos",
    icon: "pe-7s-credit",
    component: UserProfile,
    layout: "/app"
  },
  {
    path: "/user",
    name: "Usuários",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/app"
  }


];

export default dashboardRoutes;
